import utilsService from "@/mixins/utils/axios/utils.service";
const proxyEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/proxy`;

export default {
    getProxyPlanList () {
        return utilsService.getRequest(`${proxyEndpoint}/plans`);
    },
    insertProxyPlan (...args) {
        return utilsService.postRequest(`${proxyEndpoint}/plan/insert`, ...args);
    },
    activeProxyPlan (...args) {
        return utilsService.putRequest(`${proxyEndpoint}/plan/update/active`, ...args);
    },
    inactiveProxyPlan (...args) {
        return utilsService.putRequest(`${proxyEndpoint}/plan/update/inactive`, ...args);
    },
    updateProxyPlan (...args) {
        return utilsService.putRequest(`${proxyEndpoint}/plan/update`, ...args);
    },
    deleteProxyPlan (...args) {
        return utilsService.postRequest(`${proxyEndpoint}/plan/delete`, ...args);
    },
    proxyListStorage () {
        return utilsService.getRequest(`${proxyEndpoint}/storages`);
    },
    getProxyActivePlanList () {
        return utilsService.getRequest(`${proxyEndpoint}/plans`);
    },
    assignProxy (...args) {
        return utilsService.postRequest(`${proxyEndpoint}/assign`, ...args);
    },
    getProxyServiceList () {
        return utilsService.getRequest(`${proxyEndpoint}/list-service`);
    },
    updateProxyService (...args) {
        return utilsService.putRequest(`${proxyEndpoint}/update-service`, ...args);
    },
    getProxyDetailList (proxyServiceId) {
        return utilsService.getRequest(`${proxyEndpoint}/list-proxy-detail`, { id: proxyServiceId });
    },
    checkStatusProxyService (proxyServiceId) {
        return utilsService.getRequest(`${proxyEndpoint}/status`, { id: proxyServiceId });
    },
    restartProxyService (proxyServiceId) {
        return utilsService.getRequest(`${proxyEndpoint}/restart`, { id: proxyServiceId });
    },
    startProxyService (proxyServiceId) {
        return utilsService.getRequest(`${proxyEndpoint}/start`, { id: proxyServiceId });
    },
    stopProxyService (proxyServiceId) {
        return utilsService.getRequest(`${proxyEndpoint}/stop`, { id: proxyServiceId });
    },
    cancelProxyService (proxyServiceIds, cancelMode) {
        return utilsService.putRequest(`${proxyEndpoint}/cancel`, {
            ids: proxyServiceIds,
            cancel_mode: cancelMode
        });
    },
    revokeCancellationProxyService (proxyServiceIds) {
        return utilsService.putRequest(`${proxyEndpoint}/revoke-cancellation`, {
            ids: proxyServiceIds
        });
    },
    deleteClientProxyService (proxyServiceIds) {
        return utilsService.deleteRequest(`${proxyEndpoint}/delete`,
            {
                id: proxyServiceIds
            }
    )},
}
