<template>
  <custom-select :value="value"
                 :reduce="reduce"
                 :label="label"
                 :options="userList"
                 display-field="user_name"
                 :placeholder="placeholder"
                 @input="updateValue"
  >
    <template #option="{item}">
      <span><small><strong>{{ item['user_name'] }}</strong> ({{ item['facebook_name'] }} - {{ item['email'] }})</small></span>
    </template>
    <template #selected-option="{item}">
      <span><strong>{{ item['user_name'] }}</strong> ({{ item['facebook_name'] }} - {{ item['email'] }})</span>
    </template>
  </custom-select>
</template>

<script>
import CustomSelect from "@/views/components/form/CustomSelect.vue";
import DataRequester from "@/mixins/utils/dataRequester";
import useJwt from "@/auth/jwt/useJwt";
import userService from "@/mixins/utils/axios/user.service";

export default {
  name: 'UserList',
  mixins: [DataRequester],
  components: {CustomSelect},
  props: {
    value: {
      type: [String, Number, Object],
      default: ''
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    reduce: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      userList: []
    }
  },
  created() {
    let self = this

    this.runApi(userService.listAllUsers(), function (result) {
      self.userList = result.data
    })
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>
